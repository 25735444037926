import { AiFillFacebook } from "react-icons/ai"
import { FcGoogle } from "react-icons/fc"
import { IoLogoTwitter } from "react-icons/io"

type Props = {
  errorMessage: string
  formattedFacebookError?: string
  formattedGoogleError?: string
  formattedTwitterError?: string
}

const AuthErrors = ({
  errorMessage,
  formattedFacebookError,
  formattedGoogleError,
  formattedTwitterError,
}: Props) => {
  return (
    <>
      {errorMessage && (
        <div
          className="d-flex mb-4 alert alert-small rounded-sm shadow-xl bg-red-dark"
          role="alert"
        >
          <span className="rounded-start">
            <i className="fa fa-times text-white"></i>
          </span>
          <strong>{errorMessage}</strong>
        </div>
      )}
      {formattedFacebookError && (
        <div
          className="d-flex mb-4 alert alert-small rounded-sm shadow-xl bg-red-dark"
          role="alert"
        >
          <span className="rounded-start">
            <i>
              <AiFillFacebook />
            </i>
          </span>
          <strong>{formattedFacebookError}</strong>
        </div>
      )}
      {formattedGoogleError && (
        <div
          className="d-flex mb-4 alert alert-small rounded-sm shadow-xl bg-red-dark"
          role="alert"
        >
          <span className="rounded-start">
            <i>
              <FcGoogle />
            </i>
          </span>
          <strong>{formattedGoogleError}</strong>
        </div>
      )}
      {formattedTwitterError && (
        <div
          className="d-flex mb-4 alert alert-small rounded-sm shadow-xl bg-red-dark"
          role="alert"
        >
          <span className="rounded-start">
            <i>
              <IoLogoTwitter />
            </i>
          </span>
          <strong>{formattedTwitterError}</strong>
        </div>
      )}
    </>
  )
}

export default AuthErrors
