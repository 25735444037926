import { AiFillFacebook } from "react-icons/ai"
import { FcGoogle } from "react-icons/fc"
import { IoLogoTwitter } from "react-icons/io"

interface Props {
  signInWithFacebook: () => void
  facebookLoading: boolean
  signInWithGoogle: () => void
  googleLoading: boolean
  signInWithTwitter: () => void
  twitterLoading: boolean
}
const spinner = <div className="spinner-border m-2 color-blue-dark"></div>

const SocialAuth = ({
  signInWithFacebook,
  facebookLoading,
  signInWithGoogle,
  googleLoading,
  signInWithTwitter,
  twitterLoading,
}: Props) => {
  return (
    <div className="d-flex justify-content-center gap-3">
      {facebookLoading ? (
        spinner
      ) : (
        <AiFillFacebook
          onClick={() => signInWithFacebook()}
          className="rounded-circle shadow-l social-icon"
          role="button"
          color="#3b5998"
          size={20}
        />
      )}
      {googleLoading ? (
        spinner
      ) : (
        <FcGoogle
          onClick={() => signInWithGoogle()}
          className="rounded-circle shadow-l social-icon"
          role="button"
          size={20}
        />
      )}
      {twitterLoading ? (
        spinner
      ) : (
        <IoLogoTwitter
          onClick={() => signInWithTwitter()}
          className="rounded-circle shadow-l social-icon"
          color="#4099ff"
          role="button"
          size={20}
        />
      )}
    </div>
  )
}

export default SocialAuth
