export default function getRefinedFirebaseAuthErrorMessage(
  errorMessage?: string,
): string {
  return (
    errorMessage
      ?.replace("Firebase: Error ", "")
      ?.replace("Firebase: ", "")
      ?.replace("(auth/", "")
      ?.replace(").", "")
      ?.split("-")
      ?.join(" ") ?? ""
  )
}
